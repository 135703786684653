<template>
  <div class="teams-wrapper">
    <Header
      :title="title"
      :subtitle="subtitle"
      :iconPath="iconPath"
      :showAlert="currentView === 'teams'"
    >
      <template v-slot:header-buttons>
        <ToggleComponent
          :checked="currentView === 'teams'"
          @change="isChecked"
        />
      </template>
      <template v-slot:alert-content>
        <div class="d-flex">
          <img
            src="@/assets/images/icons/alert-icon.svg"
            alt="alert"
            width="20"
            height="20"
          />
          <p class="mb-0 teams-alert__content">
            {{ $t("teams.teams.info.label") }}
          </p>
        </div>
        <div v-if="showSuccessAlert">
          <img
            src="@/assets/images/icons/alert-success.svg"
            alt="alert"
            width="20"
            height="20"
          />
          <p class="teams-alert__content">{{ $t("teams.topics.success") }}</p>
        </div>
      </template>
    </Header>
    <div v-if="currentView === 'teams'">
      <PaginationV2
        key="all-teams"
        dataComponentPath="components/Teams/PaginationWrappers/TopicsListWrapper.vue"
        :dataFetchURL="getTopicsListURL(courseId)"
        :authToken="authToken"
      ></PaginationV2>
    </div>
    <div v-else>
      <PaginationV2
        key="my-teams"
        dataComponentPath="components/Teams/PaginationWrappers/TeamsListWrapper.vue"
        emptyStateComponentPath="components/Teams/PaginationWrappers/MyTeamsListEmptyWrapper.vue"
        :dataFetchURL="getMyTeamsListURL(courseId)"
        :authToken="authToken"
        :additionalProps="additionalProps()"
        @browseAllTeams="currentView = 'teams'"
      ></PaginationV2>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import TeamsPaginationMixin from "@/components/Teams/PaginationWrappers/mixin.js";

import Header from "./Shared/Header.vue";
import ToggleComponent from "./ToggleComponent.vue";

export default {
  mixins: [TeamsPaginationMixin],
  components: { Header, ToggleComponent },
  computed: {
    ...mapGetters(["getTopicsList", "getMyTeamsList", "getTopicDetail"]),
    courseId() {
      return this.$route.params.id;
    },
    iconPath() {
      return this.currentView === "teams"
        ? require("@/assets/images/icons/teams-icon.svg")
        : require("@/assets/images/icons/my-teams-icon.svg");
    },
    title() {
      return this.currentView === "teams"
        ? this.$t("teams.heading")
        : this.$t("teams.toggle.my_teams");
    },
    subtitle() {
      return this.currentView === "teams" ? this.$t("teams.subheading") : "";
    }
  },
  data() {
    return {
      showSuccessAlert: false,
      currentView: "teams",
      themeColors: ["#D3CBFF", "#B1E8BF", "#FEC2CD", "#C4E7EE", "#FFE597"]
    };
  },
  watch: {
    getMyTeamsList(l) {
      if (l.length === 0) {
        this.currentView = "teams";
      } else {
        this.currentView = "myTeams";
      }
    }
  },
  mounted() {
    this.listMyTeamsList();
  },
  methods: {
    openNewTeamModal() {
      this.$bvModal.show("create-team-modal");
    },
    isChecked(val) {
      // change layout on the basis of val-> true or false
      if (val) {
        this.currentView = "teams";
      } else {
        this.currentView = "myTeams";
      }
    },
    listMyTeamsList() {
      this.$store.dispatch("getMyTeamsList", {
        courseId: this.$route.params.id
      });
    },
    additionalProps() {
      return {
        themeColor: this.getThemeColor(this.getRandomIndex(this.themeColors))
      };
    },
    getRandomIndex(arr) {
      return Math.floor(Math.random() * arr.length);
    },
    getThemeColor(i) {
      return this.themeColors[i % this.themeColors.length];
    }
  }
};
</script>
<style lang="scss" scoped>
.teams-wrapper {
  padding: 0 40px;
  text-align: left;
  .teams-header {
    .teams-heading {
      h4 {
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #44464e;
        margin: 0px;
        text-align: initial;
      }
      p {
        font-family: $font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #191c1d;
      }
    }
    .transparent-btn {
      border: 1px solid #0057e0;
      border-radius: 100px;
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #0057e0;
      background-color: white;
    }
  }
  .teams-alert {
    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #0057e0;
      margin: 2px 0 0 10px;
    }
  }
  .teams-alert-success {
    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #00c781;
      margin: 2px 0 0 10px;
    }
  }
}
#create-team-modal {
  padding: 10px;
  .teams-header-badge {
    p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      color: #0057e0;
      margin-bottom: 0px;
    }
    span {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #44464e;
    }
  }
  .body-head {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #25282b;
  }
  .error {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #44464e;
  }
}
</style>
