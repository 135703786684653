<template>
  <label class="toggle-switch nolabel" @change="onChange">
    <input type="checkbox" :checked="isChecked" v-model="isChecked" />
    <a class="toggle-switch__bg"></a>
    <div class="toggle-switch__div">
      <span :class="['toggle-switch__content--left', { active: !checked }]">{{
        $t("teams.toggle.my_teams")
      }}</span>
      <span :class="['toggle-switch__content--right', { active: checked }]">{{
        $t("teams.toggle.all_teams")
      }}</span>
    </div>
  </label>
</template>
<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: true
    };
  },
  watch: {
    checked(c) {
      this.isChecked = c;
    }
  },
  methods: {
    onChange() {
      this.$emit("change", this.isChecked);
    }
  }
};
</script>
<style lang="scss" scoped>
.toggle-switch {
  position: relative;
  cursor: pointer;
  width: 344px;
  background: #f1f1f1;
  border-radius: 100px;
  height: 46px;
  margin: 10px 5px 0 0;
  .toggle-switch__bg {
    position: absolute;
    z-index: 0;
    top: 3px;
    bottom: 3px;
    left: 3px;
    width: 50%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 100px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggle-switch__div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    span {
      font-family: $font-family;
      letter-spacing: 1.25px;
      color: #52575c;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding-left: 20px;
      z-index: 1;
      text-transform: uppercase;
      &.active {
        color: #0057e0;
      }
    }
  }
  input {
    position: absolute;
    opacity: 0;
    &:checked {
      & ~ a {
        left: calc(50% - 3px);
        right: auto;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .toggle-switch {
    width: 250px;
    margin-top: 15px;
  }
}

// rtl styles
[dir="rtl"] {
  .toggle-switch {
    .toggle-switch__bg {
      left: calc(50% - 3px);
    }
    input {
      &:checked {
        & ~ .toggle-switch__bg {
          right: calc(50% - 3px);
          left: auto;
        }
      }
    }
  }
}
</style>
